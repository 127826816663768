<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NewsCard5") }}</h1>
        <div id="news-contain">
          <img
            height="80"
            width="200"
            :src="getImageUrlbyName('NewsBig5')"
            href=""
            id="pic"
            align="left"
          />
          <div id="innews">
            <div id="inf-news-carddate">{{ $t("message.CNDate5") }}</div>
          </div>
          <span id="pad" v-html="$t('message.News5Text1')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News5Text2')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News5Text3')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News5Text4')"></span>      
          <div id="innews">
            <a id="backto" @click="$router.push('/informs/news')">
              <v-icon large>mdi-arrow-left-bold-circle</v-icon>
              <a id="pad-left">{{ $t("message.BackToList") }}</a>
            </a>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
};
</script>
<style>
</style>